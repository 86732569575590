/* {css for notification} */
.bell-notifty{float: right; position: relative; margin-right: 10px;}
.contact-note{display: block;width: 17px;height: 17px;position: absolute;border-radius:50%;top: -5px;left: 18px;background-color:red;}
.contact-note h5{font-size: 10px; color: #fff;padding-top: 2px;}
* CSS used here will be applied after bootstrap.css */
.bell-notifty .fa-bell{color: #250a72;}
.bell-notifty .fa-bell:hover{color: #206F9E;}
.bell-notifty .dropdown {display:inline-block;margin-left:20px;padding:10px;}
.bell-notifty .notifications { min-width:420px;margin-top: 36px;margin-right: 20px; }
.bell-notifty .notification-item:hover{box-shadow: 0 4px 4px rgba(0,0,0,0.25);background-color: #d7d8e5;}
.bell-notifty .notifications-wrapper {overflow-y: auto;
    height: 355px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.bell-notifty .notification-heading, .notification-footer  {padding:2px 10px;}
.bell-notifty.dropdown-menu.divider {margin:5px 0;}   
.bell-notifty .item-title {font-size:14px;color:#000;margin: 0!important;line-height: 17px;}
.bell-notifty .notifications a.content {text-decoration:none;background: rgba(249,249,249,0.7);}
.bell-notifty .notifications a.content {text-decoration:none;background: rgba(249,249,249,0.7);}
.bell-notifty .notification-item {display:flex;padding:10px;align-items: center;margin:5px;  border-bottom: solid 1px #d7d8e5;border-radius:4px;background: #F7F7FA;}
.bell-notifty .btn-sty-title {background: #fff;border: none;box-shadow: none;font-size: 17px!important;}
.bell-notifty .notification-item .dfdjfd {line-height: 39px;margin-right: 10px;width: 40px;height: 40px;color: #fff;background-color: rgb(15, 93, 209);text-align: center;border-radius: 50%;}
.bell-notifty .date-note {color:blue;}
.bell-notifty .notification-item .indicator {width: 15px;height: 15px;display:inline-block;background-color:rgb(85, 184, 28);border-radius: 50%;}
.bell-notifty .bell-notifty .tab-label{text-transform: lowercase !important;}