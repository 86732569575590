.rolesOuter{height:100vh;background:url(../../../assets/images/login-bg.jpg);background-size:cover;background-position: center center;}
.rolesBlockHolder{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.rolesHolder{padding:25px 30px;background: #D8D8D8;border: 2px solid #4A4A4A;-webkit-box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);border-radius: 10px;}
.role-foot-btns {padding: 0 20px;margin-top: 30px;}
.rolesIconsHolder{font-size:0;letter-spacing:0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.rolesIconsHolder li{width:150px;height:150px;border-radius:50%;opacity: 0.5;border: 1px solid #250A72;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin:0 15px;position:relative;}
.rolesIconsHolder li.active{border-width:4px;opacity: 1;}
.rolesIconsHolder li.active:after{width:42px;height:42px;background:#fff;border-radius:50%;position:absolute;right:-12px;top:21px;content:url('../../../assets/images/correct.svg');padding:7px 1px 0 1px;}
.rolesIconsHolder .roleIcon{margin:0 0 15px}
.rolesIconsHolder .roleName{font-size: 16px;line-height: 20px;color: #250A72;}
.rolesLogoholder{width:545px;position: absolute;right:0;bottom: 50px;}
.goBackButton{width:103px;height:96px;position:absolute;top:25px;right:10%;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;z-index:1;}
.goBackButton.polygonGreen{background:url('../../../assets/images/polygon-green.svg');background-size:contain;background-position:center center;}
.goBackButton .polygonImage{position: absolute;left: 0;top:0;width:100%;height:auto;z-index:-1;}
.goBackButton img{width:15px;margin-bottom:10px;}
.goBackButton .btnText{font-size: 11px;line-height: 13px;}
/* Create Role screen style */
.select-usr-optns-wrap h6 {
    font-size: 14px;
    color: #525252;
    font-weight: 400;
}
.select-usr-optns-wrap {
    margin-top: 18px;
    margin-right: 30px;
}
.select-usr-optns-wrap:last-child {
	margin-right: 0;
}
.role-content-wrap .date-range-picker span {
	padding: 0;
	border: unset;
	border-radius: 3px;
}
.role-content-wrap .date-range-picker {
    margin-right: 10px;
}
.role-content-wrap .date-range-picker span input {
	font-weight: 500;
    font-size: 13px;
	line-height: 14px;
	height: 100%;
	padding-right: 20px;
}
.role-content-wrap .date-range-picker span input{padding: 6.5px 10px 4px !important}
.role-content-wrap.system-cat-filter .fil-wrap-ctn .custom-select-menu.filter-searchbar {
    min-width: 400px;
}
/* Responsive Queriess */
@media only screen and (max-width:1600px){
	.role-content-wrap .date-range-picker span {width: 150px;}
	.role-content-wrap.system-cat-filter .fil-wrap-ctn .custom-select-menu.filter-searchbar {
		min-width: 320px;
	}
}
@media only screen and (max-width:1367px){
	.role-content-wrap .date-range-picker span {
		width: 130px;
	}
	.role-content-wrap.system-cat-filter .fil-wrap-ctn .custom-select-menu.filter-searchbar {
		min-width: 220px;
	}
}
@media only screen and (max-width:1200px){
	.rolesLogoholder{width:390px;}
	.role-content-wrap .date-range-picker span {
		width: auto;min-width: 130px;
	}
	.role-content-wrap.system-cat-filter .fil-wrap-ctn .custom-select-menu.filter-searchbar {
		min-width: 50%;
		margin-right: 0 !important;
	}	
}
@media only screen and (max-width:768px){
	.rolesLogoholder{top:25px;left:50%;right:auto;bottom:auto;-ms-transform: translateX(-50%);-webkit-transform: translateX(-50%);transform: translateX(-50%);width: -webkit-max-content;width: -moz-max-content;width: max-content;}
	.rolesIconsHolder li{width:120px;height:120px;}
	.rolesIconsHolder li.active::after{right:-18px;}
	.goBackButton{width:70px;height:65px;right:25px;}
	.goBackButton img{width:9px;margin-bottom: 4px;}
	.goBackButton .btnText {font-size: 8px;}
	.role-content-wrap.system-cat-filter .fil-wrap-ctn {flex-direction: row !important;}
	.role-content-wrap.system-cat-filter .fil-wrap-ctn .custom-select-menu.filter-searchbar {min-width: auto;}
}
@media only screen and (max-width: 576px){
	.goBackButton{width:75px;height:70px;top:10px;right:5px;}
	.goBackButton img{margin-bottom:0;}
	.rolesLogoholder{width:160px;bottom: auto;right:0;}
	.rolesHolder{padding:15px;}
	.rolesBlockHolder{display:block;padding:30px 10px 20px;}
	.rolesBlockHolder .rolesHolder{width:100%;}
	.rolesIconsHolder li{width:100px;min-width:100px;height:100px;margin:0 5px 7px;}
	.rolesIconsHolder li.active::after{right:-5px;}
	.rolesIconsHolder .roleIcon{margin:0 0 8px;}
	.rolesIconsHolder .roleIcon img{max-width: 23px;}
	.rolesIconsHolder .roleName{font-size:14px;}
	.rolesIconsHolder li.active::after {width:32px;height:32px;padding:2px 1px 0;top:2px;right:-8px;}
	.role-foot-btns {padding: 0;}
	.role-foot-btns .btn.btn-theme-blue {padding: 10px;font-size: 13px;}
	.role-content-wrap.system-cat-filter .fil-wrap-ctn {flex-direction: column !important;}
	.role-content-wrap .date-range-picker {margin-right: 0;margin-bottom: 5px;width: 100%;}
	.role-content-wrap .date-range-picker span {width: 100%;min-width: 115px;}
}
@media only screen and (max-width:479px){
	.rolesIconsHolder{-ms-flex-wrap: nowrap;flex-wrap: nowrap;overflow: auto;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;max-width: -webkit-max-content !important;max-width: -moz-max-content !important;max-width: max-content !important;margin: 0 auto;}
	.rolesIconsHolder li{display:inline-block;vertical-align:top;}
	.rolesIconsHolder .rolesIconHolder{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-align: center;-ms-flex-align: center;align-items: center;height:100%;}
	.rolesLogoholder {left: 10px;-webkit-transform: none;-ms-transform: none;transform: none;}
}