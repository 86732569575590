.pageWrapper{height:100vh;background:url(../../assets/images/login-bg.jpg);background-size:cover;background-position:center center;padding:50px 12px;overflow-y:auto}
.pageContainer{margin:0 0 50px;background:rgba(249,249,249,.7);border:1.5px solid #979797;-webkit-box-shadow:0 4px 4px rgba(0,0,0,.25);box-shadow:0 4px 4px rgba(0,0,0,.25);border-radius:10px;padding:30px}
.pageContent .tableHeader{border-radius:10px;overflow:hidden;margin:0 0 10px}
.pageContent .tableHeaderInner{background:#ebeae8;border:12px solid #d3d3d3;-webkit-box-sizing:border-box;box-sizing:border-box;-webkit-box-shadow:0 4px 4px rgba(0,0,0,.25);box-shadow:0 4px 4px rgba(0,0,0,.25);border-radius:10px;padding:0 11%}
.meshPackageTable{border:none;width:100%}
.meshPackageTable thead{border:none}
.meshPackageTable thead tr th{-webkit-box-shadow:inset 0 -12px 0 #d3d3d3,inset 0 12px 0 #d3d3d3,inset 0 0 0 #d3d3d3;box-shadow:inset 0 -12px 0 #d3d3d3,inset 0 12px 0 #d3d3d3,inset 0 0 0 #d3d3d3;background:#ebeae8;border:none}
.meshPackageTable thead tr th:first-child{border-top-left-radius:10px;border-bottom-left-radius:10px;-webkit-box-shadow:inset 0 -12px 0 #d3d3d3,inset 0 12px 0 #d3d3d3,inset 12px 0 0 #d3d3d3;box-shadow:inset 0 -12px 0 #d3d3d3,inset 0 12px 0 #d3d3d3,inset 12px 0 0 #d3d3d3}
.meshPackageTable thead tr th:last-child{border-top-right-radius:10px;border-bottom-right-radius:10px;-webkit-box-shadow:inset 0 -12px 0 #d3d3d3,inset 0 12px 0 #d3d3d3,inset -12px 0 0 #d3d3d3;box-shadow:inset 0 -12px 0 #d3d3d3,inset 0 12px 0 #d3d3d3,inset -12px 0 0 #d3d3d3}
.meshPackageTable tbody td,.meshPackageTable thead th{vertical-align:middle;text-align:center}
.meshPackageTable thead th{width:12%;padding:16px 10px 20px}
.title-th-wrap{width:40%;text-align:right;font-size:24px;line-height:29px;font-weight:700;color:#250a72}
.meshPackageTable .btn{min-width:150px;padding:12px 10px 12px;-webkit-box-shadow:0 4px 4px rgba(0,0,0,.25);box-shadow:0 4px 4px rgba(0,0,0,.25);border-radius:10px;color:#000;font-size:15px;font-weight:700;border:1px solid}
.meshPackageTable .tableHeadingText{padding-right:35px}
.meshPackageTable .mesh-btn-brown{background:#c15c40;border-color:#c15c40}
.meshPackageTable .btn-brown:focus,.meshPackageTable .mesh-btn-brown:hover{background:0 0;border-color:#c15c40;color:#c15c40}
.meshPackageTable .mesh-btn-grey{background:#d9d8d8;border-color:#d9d8d8}
.meshPackageTable .btn-grey:focus,.meshPackageTable .mesh-btn-grey:hover{background:0 0;border-color:#d9d8d8;color:#d9d8d8}
.meshPackageTable .mesh-btn-yellow{background:#f1c62b;border-color:#f1c62b}
.meshPackageTable .btn-yellow:focus,.meshPackageTable .mesh-btn-yellow:hover{background:0 0;border-color:#f1c62b;color:#f1c62b}
.meshPackageTable tbody:before{line-height:1em;content:".";display:block}
.meshPackageTable .badge.badge-primary{border-radius:10px;padding:3px 10px;background:#250a72;-webkit-box-shadow:0 4px 4px rgba(0,0,0,.25);box-shadow:0 4px 4px rgba(0,0,0,.25);font-weight:700;font-size:12px;line-height:15px}
.meshPackageTable tfoot tr{background:#ebeae8}
.meshPackageTable tbody td{width:12%;text-align:center;border:none;padding:5px 12px}
.meshPackageTable tbody tr:nth-child(odd) td{background:#d3d3d3}
.meshPackageTable tbody tr:nth-child(even) td{background:#94989e}
.meshPackageTable tbody tr:first-child td{background:#ebeae8}
.meshPackageTable tbody tr td:nth-child(2){width:40%;text-align:left}
.meshPackageTable tbody tr td:first-child,.meshPackageTable tbody tr td:last-child{background:#ebeae8}
.meshPackageTable tfoot tr td{width:12%;padding:7px 10px 9px;border:none;color:#fff;font-weight:600}
.meshPackageTable tfoot tr td:nth-child(2){width:40%}
.meshPackageTable tfoot tr:nth-child(odd) td{background:#85c33d}
.meshPackageTable tfoot tr:nth-child(even) td{background:#54841d}
.meshPackageTable tfoot tr td:first-child,.meshPackageTable tfoot tr td:last-child,.meshPackageTable tfoot tr.spacerRow td,.meshPackageTable tfoot tr:last-child td{background:#ebeae8}
.meshPackageTable tbody tr td{-webkit-box-shadow:inset 0 0 0 #ebeae8,inset 0 0 0 #ebeae8,inset 0 0 0 #ebeae8;box-shadow:inset 0 0 0 #ebeae8,inset 0 0 0 #ebeae8,inset 0 0 0 #ebeae8;background:#ebeae8;border:none;background:#ebeae8}
.meshPackageTable tbody tr td:first-child{-webkit-box-shadow:inset 0 0 0 #ebeae8,inset 0 0 0 #ebeae8,inset 0 0 0 #ebeae8;box-shadow:inset 0 0 0 #ebeae8,inset 0 0 0 #ebeae8,inset 0 0 0 #ebeae8}
.meshPackageTable tbody tr td:last-child{-webkit-box-shadow:inset 0 0 0 #ebeae8,inset 0 0 0 #ebeae8,inset 0 0 0 #ebeae8;box-shadow:inset 0 0 0 #ebeae8,inset 0 0 0 #ebeae8,inset 0 0 0 #ebeae8}
.meshPackageTable tbody tr:first-child td:first-child{border-top-left-radius:10px}
.meshPackageTable tbody tr:first-child td:last-child{border-top-right-radius:10px}
.pageHeader-details-wrap{background:#250a72;border-radius:10px;-webkit-box-shadow:3px 3px 4px #4a4a4a;box-shadow:3px 3px 4px #4a4a4a;padding:10px 30px}
.meshPackageTable.upgradeCli-optns-list .cli-optn-list{padding:10px 20px}
.meshPackageTable.upgradeCli-optns-list .cli-optn-list li{margin-right:70px}
.upgradeClient-table .meshPackageTable thead tr th{-webkit-box-shadow:inset 0 -3px 0 #000,inset 0 3px 0 #000,inset 0 0 0 #000;box-shadow:inset 0 -3px 0 #000,inset 0 3px 0 #000,inset 0 0 0 #000;background:#d7d3d3}
.upgradeClient-table .meshPackageTable thead tr th:first-child{border-top-left-radius:10px;border-bottom-left-radius:10px;-webkit-box-shadow:inset 0 -3px 0 #000,inset 0 3px 0 #000,inset 3px 0 0 #000;box-shadow:inset 0 -3px 0 #000,inset 0 3px 0 #000,inset 3px 0 0 #000}
.upgradeClient-table .meshPackageTable thead tr th:last-child{border-top-right-radius:10px;border-bottom-right-radius:10px;-webkit-box-shadow:inset 0 -3px 0 #000,inset 0 3px 0 #000,inset -3px 0 0 #000;box-shadow:inset 0 -3px 0 #000,inset 0 3px 0 #000,inset -3px 0 0 #000}
.upgradeClient-table .meshPackageTable thead tr th .btn-sm-rounded{font-size:14px;border:2px solid #f9f9f9;border-radius:20px;padding:3px 12px 3px;line-height:18px;-webkit-box-shadow:none;box-shadow:none;font-weight:500}
.upgradeClient-table .meshPackageTable tbody tr td .btn-sm-rounded{text-align:center;-webkit-box-shadow:0 4px 4px rgba(0,0,0,.25);box-shadow:0 4px 4px rgba(0,0,0,.25);border-radius:10px;padding:8px 20px}
.upgradeClient-table .meshPackageTable tbody td,.upgradeClient-table .meshPackageTable thead th{width:auto;text-align:center}
.upgradeClient-table .meshPackageTable tbody td{background:#f9f9f9!important;-webkit-box-shadow:inset 0 1px 0 rgb(0 0 0),inset 0 -1px 0 rgb(0 0 0)!important;box-shadow:inset 0 1px 0 rgb(0 0 0),inset 0 -1px 0 rgb(0 0 0)!important}
.upgradeClient-table .meshPackageTable tbody tr:after{content:'';width:100%;height:1px;background:#f9f9f9!important}
.upgradeClient-table .meshPackageTable tbody td:first-child{border-top-left-radius:10px;border-bottom-left-radius:10px;-webkit-box-shadow:inset 1px 1px 0 rgb(0 0 0),inset 0 -1px 0 rgb(0 0 0)!important;box-shadow:inset 1px 1px 0 rgb(0 0 0),inset 0 -1px 0 rgb(0 0 0)!important}
.upgradeClient-table .meshPackageTable tbody td:last-child{border-top-right-radius:10px;border-bottom-right-radius:10px;-webkit-box-shadow:inset -1px 1px 0 rgb(0 0 0),inset 0 -1px 0 rgb(0 0 0)!important;box-shadow:inset -1px 1px 0 rgb(0 0 0),inset 0 -1px 0 rgb(0 0 0)!important}
.upgradeClient-table .meshPackageTable tbody tr td:nth-child(2){width:auto;text-align:center}
.upgradeClient-table .meshPackageTable tbody tr td .upg-points-wrap .btn-sm-rounded{margin-bottom:10px}
.upgradeClient-table .meshPackageTable tbody tr td .upg-points-wrap .btn-sm-rounded:last-child{margin-bottom:0}
.upgradeClient-table .meshPackageTable tbody tr td .cli-up-checks span{margin-bottom:10px;width:25px;height:30px}
.upgradeClient-table .meshPackageTable tbody tr td .cli-up-checks span img{width:23px}
.upgradeClient-table .meshPackageTable tbody tr td .cli-up-checks span:last-child{margin-bottom:0}
.upgradeClient-table .meshPackageTable tbody tr td .cli-up-title{max-width:150px;min-width:150px;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;font-size:14px;font-weight:500}
.upg-points-wrap{font-size:14px;font-weight:500}
.upgrade-cli-par-cnt .logoHolder img{width:190px}
.upgrade-cli-par-cnt .pageHeader-details-wrap p{font-size:15px}
.upgrade-cli-par-cnt .title-th-wrap{font-size:19px}

/* Responsive Queries */
@media only screen and (max-width:1600px){
.upgrade-cli-par-cnt .logoHolder img{width:140px}
.upgrade-cli-par-cnt .pageHeader-details-wrap p{font-size:11px}
.upgrade-cli-par-cnt .meshPackageTable .btn{font-size:12px;font-weight:600;min-width:120px;padding:9px 10px 7px}
.upgrade-cli-par-cnt .title-th-wrap{font-size:15px}
.upgradeClient-table .meshPackageTable thead tr th .btn-sm-rounded{font-size:11px!important}
.upg-points-wrap{font-size:11px}
.upgradeClient-table .meshPackageTable tbody tr td .cli-up-title{font-size:12px}
.upgradeClient-table .meshPackageTable tbody tr td .cli-up-checks span img{width:20px}
.upgradeClient-table .meshPackageTable tbody tr td .cli-up-checks span{height:28px}
}
@media only screen and (max-width:1023px){
.pageContent .tableHeaderInner{padding:0 2%}
.pageContent .tableContent{padding:11px 2%}
}
@media only screen and (max-width:992px){
.pageWrapper{padding:20px 10px}
.pageContainer{padding:15px;margin:0 0 20px}
.meshPackageTable tbody tr td,.meshPackageTable tfoot tr td,.meshPackageTable thead tr th{width:18%}
.meshPackageTable tbody tr td:first-child,.meshPackageTable tbody tr td:last-child,.meshPackageTable tfoot tr td:first-child,.meshPackageTable tfoot tr td:last-child,.meshPackageTable thead tr th:first-child,.meshPackageTable thead tr th:last-child{width:3%}
.pageContent .tableContent{padding:11px 0}
}