.card-bx-sty{padding:15px;background:#ebebeb;border:1px solid #000;-webkit-box-shadow:0 4px 4px rgba(0,0,0,.25);box-shadow:0 4px 4px rgba(0,0,0,.25);border-radius:10px}
.order-dtls-sec-ctn{margin-bottom:30px}
.order-dtls-sec-ctn .title-dark-20{margin-right:30px;margin-top:30px;min-width:220px;text-align:right;max-width:220px}
.order-right-ctn{min-width:calc(100% - 250px);max-width:calc(100% - 250px)}
.custom-select-menu.custom-select2{max-width:400px}
.label-reg-sty{font-weight:600;font-size:14px;color:#000}
.card-bx-sty.deli-dlts-ctn-info .label-reg-sty{min-width:150px;margin-right:20px;max-width:150px;text-align:right;margin-bottom:0}
.card-bx-sty.deli-dlts-ctn-info .custom-select-menu .react-select__value-container{height:21px}
.card-bx-sty.deli-dlts-ctn-info .custom-select-menu .react-select__option{font-size:14px;font-weight:500}
.card-bx-sty.deli-dlts-ctn-info .custom-select-menu .css-b8ldur-Input{margin:0;padding-bottom:0}
.field-bx-sty{background:#fff;border:2px solid #9B9B9B;border-radius:4px;font-size:14px;font-weight:600;color:#000;min-height:38px}
/* .agent_detail .field-bx-sty{border: 1px solid #9B9B9B!important;} */
.agent_detail .form-control{border-color: #9B9B9B!important;height: calc(1.5em + 0.75rem + -6px)!important;font-size: 13px;}
.card-bx-sty.deli-dlts-ctn-info .custom-select-menu.custom-select2{max-width:100%;min-width:140px}
.field-bx-sty::-webkit-input-placeholder{color:#000}
.field-bx-sty::-moz-placeholder{color:#000}
.field-bx-sty:-ms-input-placeholder{color:#000}
.field-bx-sty:-moz-placeholder{color:#000}
.card-bx-sty.deli-dlts-ctn-info .deliv-dtls-list-sty .label-reg-sty{min-width:100%;margin-right:0;max-width:100%;text-align:center}
.card-bx-sty.deli-dlts-ctn-info .deliv-dtls-list-sty .total-count-ctn .label-reg-sty{padding:0 12px}
.deliv-note-msg-bx{background:#FFF;border:2px solid #250A72;-webkit-box-shadow:0 4px 4px rgba(0,0,0,0.25);box-shadow:0 4px 4px rgba(0,0,0,0.25);padding:15px}
.deliv-note-msg-bx p,.deliv-note-msg-bx h6{font-weight:500;font-size:12px;text-align:center;color:#250A72}
.deliv-note-msg-bx h6{font-weight:600}
.card-bx-sty.deli-dlts-ctn-info .vess-inn-wrap .custom-select-menu.custom-select2{max-width:250px}
.vess-inn-wrap .label-reg-sty{color:#250A72}
.labelReg-innSty{display:none}
.deli-dlts-ctn-info .field-bx-sty{width:calc(100% - 170px)}
.deli-dlts-ctn-info .deliv-dtls-list-sty .field-bx-sty{width:100%}

@media only screen and (max-width:1600px){
    .field-bx-sty {min-height: 30px;height: 30px;font-size: 12px;}
    .card-bx-sty.deli-dlts-ctn-info .custom-select-menu .react-select__value-container {height: 25px;}
    .field-bx-sty {min-height: 30px;height: 30px;font-size: 13px;}
    .datePicker.form-control.field-bx-sty input {line-height: normal;padding: 5px 20px;font-size: 12px;}
    .datePicker.form-control.field-bx-sty.fa-icon input {padding-left: 30px;}
    .datePicker .react-datepicker-wrapper .react-datepicker__input-container:after {border-width: 5px;border-bottom: 0;}
    .label-reg-sty {font-size: 12px;}
    .label-bold {font-size: 15px;}
    .title-dark-20 {font-size: 16px;}
    .require-note.text-danger {font-size: 13px;}
    .card-bx-sty.deli-dlts-ctn-info .custom-select-menu .react-select__option,.custom-select-menu .react-select__menu .react-select__option {font-size: 12px;padding: 3px 12px;}
    .ordCatg-dtl-btn h1 {font-size: 11px;}
    .card-bx-sty.box-item-inn.ordCatg-dtl-btn .btn.btn-yellow {padding: 4px 15px;font-size: 12px;}
    .agent_detail .form-control{border-color: #9B9B9B!important;height: calc(1.5em + 0.75rem + -6px)!important;font-size: 13px;}
}
@media only screen and (max-width:1600px) and (min-width: 1368px){
    .card-bx-sty.deli-dlts-ctn-info .content-items-sty .form-group:nth-last-of-type(1) .label-reg-sty {min-width: 120px;}
    .card-bx-sty.deli-dlts-ctn-info .content-items-sty .form-group:nth-last-of-type(1) .field-bx-sty { width: calc(100% - 140px);}
}
@media only screen and (max-width:1440px){
    .order-dtls-sec-ctn .title-dark-20 {min-width: 180px;max-width: 180px;}
    .order-right-ctn {min-width: calc(100% - 210px);max-width: calc(100% - 210px);}
}
@media only screen and (max-width:1367px){
    .content-items-sty {-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
    .content-items-sty .form-group {width: 100%;}
}
@media only screen and (max-width:1200px){
    .order-dtls-sec-ctn .title-dark-20 {min-width: 150px;max-width: 150px;}
    .order-right-ctn {min-width: calc(100% - 180px);max-width: calc(100% - 180px);}
    .card-bx-sty.deli-dlts-ctn-info .col-md-5,.card-bx-sty.deli-dlts-ctn-info .col-md-7 {width: 100%;-webkit-box-flex: 0;-ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%;}
    .deliv-note-msg-bx {margin-bottom: 30px;}
}

@media only screen and (max-width:992px){
	.pageWrapper{padding:20px 10px;}
	.pageContainer{padding: 15px;margin: 0 0 20px;}
    .order-dtls-sec-ctn .title-dark-20 {min-width: 100px;max-width: 100px;}
    .order-right-ctn {min-width: calc(100% - 130px);max-width: calc(100% - 130px);}
    .order-dtls-sec-ctn .card-bx-sty .form-group {-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
    .card-bx-sty.deli-dlts-ctn-info .label-reg-sty {min-width: 100%;margin-right: 0;max-width: 100%;text-align: left;margin-bottom: 10px;}
    .card-bx-sty.deli-dlts-ctn-info .deliv-dtls-list-sty .label-reg-sty {text-align: left;margin-bottom: 0;}
    .deliv-dtls-list-sty {-webkit-box-align: start !important;-ms-flex-align: start !important;align-items: flex-start !important;}
    .deli-dlts-ctn-info .field-bx-sty {width: 100%;}
    .labelReg-innSty {display: inline-block;}
}
@media only screen and (max-width:768px){
    .order-dtls-sec-ctn .title-dark-20 {min-width: 100%;max-width: 100%;text-align: left;margin: 0;margin-bottom: 15px;}
    .order-right-ctn {min-width: 100%;max-width: 100%;}
}
@media only screen and (max-width:479px){
    .deliv-dtls-list-sty {-webkit-box-align: start !important;-ms-flex-align: start !important;align-items: flex-start !important;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;width: 100%;}
    .form-group.total-count-ctn .label-reg-sty {margin-bottom: 0;}
    .order-dtls-sec-ctn .card-bx-sty .deliv-dtls-list-sty .form-group {width: 100%;}
}