.setting-wrapper{background:#EBEBEB;margin-top:40px;overflow:hidden}
.setting-wrapper .nav-tabs{border-bottom:none}
.setting-wrapper .nav-tabs .nav-item{margin-bottom:0;-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto;width: 14.28%;}
.setting-wrapper .nav-tabs .nav-link{color:#74777b!important;font-size:14px;font-weight:600;border:1px solid rgba(40,44,42,0.1);border-radius:0;border-right:0;text-align:center;padding:20px 15px;background:rgba(40,44,42,0.05);-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;height: 100%;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;display: -webkit-box;display: -ms-flexbox;display: flex;}
.setting-wrapper .nav-tabs .nav-item:last-child .nav-link{border-right:1px solid rgba(40,44,42,0.1)}
.setting-wrapper .nav-tabs .nav-item.show .nav-link,.setting-wrapper .nav-tabs .nav-link.active,.setting-wrapper .nav-tabs .nav-link:focus,.setting-wrapper .nav-tabs .nav-link:hover{background-color:transparent;border-color:rgba(40,44,42,0.1);-webkit-box-shadow:inset 0 3px 0 #2CC185;box-shadow:inset 0 3px 0 #2CC185;color:#2CC185!important;border-bottom:none;border-top:none}
.settings-steps-wrap .table.theme-table-wrap thead th{vertical-align:middle!important;font-size: 14px !important;}
.settings-steps-wrap .table.theme-table-wrap tbody td{font-size: 13px !important;color: #646464 !important;}
/* .settings-steps-wrap .table.theme-table-wrap.crew-info-table thead tr:first-child th:first-child{width:140px} */
.settings-steps-wrap .table.theme-table-wrap.crew-info-table thead tr:first-child th:nth-last-of-type(2){width:200px}
/* .settings-steps-wrap .table.theme-table-wrap.crew-info-table tbody tr td{text-align:center} */
.settings-steps-wrap .table.theme-table-wrap.crew-info-table thead tr:first-child th:last-child,.settings-steps-wrap .table.theme-table-wrap.operation-info-table thead tr:first-child th{border-bottom:none}
.settings-steps-wrap .table.theme-table-wrap.crew-info-table thead tr:nth-child(2) th,.settings-steps-wrap .table.theme-table-wrap.operation-info-table thead tr:nth-child(2) th{border-top:none}
.setting-wrapper table{position:relative}
.setting-wrapper thead tr th{position:-webkit-sticky!important;position:sticky!important;top:0!important}
.invoice-info-table .form-control{background:#f2f2f2;height:30px;outline:none!important;-webkit-box-shadow:none!important;box-shadow:none!important;font-size:13px}

/* wizard form style */
.wizardSteps-wrap{text-align:center;position:relative;margin-top:40px;background:transparent;display:-webkit-box;display:-ms-flexbox;display:flex}
.wizardSteps-wrap fieldset .form-card{padding:20px 0 0 20px;width:calc(100% - 70px);margin:0;position:relative;text-align:left;color:#9E9E9E}
.wizard-dis-title{font-size:15px;color:#838383}
.wizard-title{font-size:22px;font-weight:600}
.wizard-fieldset-wrap{width:calc(100% - 160px);position:relative;z-index:0;background: #f4f4f4;border-radius: 30px;overflow: hidden;}
.wizard-fieldset-wrap .loader-wrap { filter: none; -webkit-filter: none;}
.wizard-fieldset-wrap .mesh-spinner svg text {stroke: #206f9e;font-size: 11px;stroke-width: 0.45px;}
.wizard-inn-wrap.formBox{position:relative;z-index:0}
.wizard-inn-wrap.formBox:after{content:'';position:absolute;width:280px;height:100%;background-color:#206f9e;right:0;top:0;z-index:-1;border-top-right-radius:9px;border-bottom-right-radius:9px}
.wizardSteps-wrap fieldset{ margin:0; padding-bottom:40px; position:relative; width:100%; background:transparent; border-radius:0; min-height:580px; text-align:right; -webkit-border-radius:0; -moz-border-radius:0; -ms-border-radius:0; -o-border-radius:0; }
.wizardSteps-wrap fieldset form {display: block !important;position: unset !important;opacity: 1 !important;}
.wizardSteps-wrap fieldset:not(:first-of-type){display:none}
.form-thanks-you-wrap{min-height:520px}
.form-thanks-you-wrap h2{font-size:40px;font-weight:600;color:#5a5a5a}
.form-thanks-you-wrap p{font-size:15px}
.thank-you-icon-wrap h3{font-size:60px;width:100%;color:#8BC34A;margin-top:20px}
.wizardSteps-wrap .action-button,.wizardSteps-wrap .action-button-previous{margin:0 auto;width:-webkit-max-content;width:-moz-max-content;width:max-content;min-width:120px;display:inline-block;padding:11px 15px 10px;font-size:15px;position:absolute;right:0;bottom:40px}
.wizardSteps-wrap .action-button:last-child{margin-right:70px}
.wizardSteps-wrap .action-button-previous{right:200px}
.setting-wizard-wrap select.list-dt{border:none;outline:0;border-bottom:1px solid #ccc;padding:2px 5px 3px;margin:2px}
.setting-wizard-wrap select.list-dt:focus{border-bottom:2px solid #87ceeb}
.setting-wizard-wrap .card{z-index:0;border:none;border-radius:.5rem;position:relative;overflow:hidden}
.setting-wizard-wrap .fs-title{font-size:21px;color:#0399ac;padding-bottom:10px;margin-bottom:30px;font-weight:600;text-align:left;position:relative}
.setting-wizard-wrap .fs-title:before,.setting-wizard-wrap .fs-title:after{content:'';position:absolute;width:60px;height:2px;background-color:#0399ac;bottom:0;left:0}
.setting-wizard-wrap .fs-title:after{width:4px;height:4px;left:64px;bottom:-1px;border-radius:50%}
.setting-wizard-wrap .wizard-progressbar{margin-bottom:0;overflow:hidden;color:#d3d3d3;padding-left:0;-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2;margin-left:-25px;width:160px;z-index:9;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}
.setting-wizard-wrap .wizard-progressbar .active{color:#fff}
.setting-wizard-wrap .wizard-progressbar li{list-style-type:none;font-size:14px;width:100%;position:relative;z-index:0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:5px 3px;padding-bottom:20px;font-weight:600;color:#ffffff6b}
.setting-wizard-wrap .wizard-progressbar li:last-child{padding-bottom:3px}
.setting-wizard-wrap .wizard-progressbar #General:before{font-family:"Font Awesome 5 Free";content:"\f007"}
.setting-wizard-wrap .wizard-progressbar #Crew:before{font-family:"Font Awesome 5 Free";content:"\f0c0"}
.setting-wizard-wrap .wizard-progressbar #Operation:before{font-family:"Font Awesome 5 Free";content:"\f0ac"}
.setting-wizard-wrap .wizard-progressbar #Ports:before{font-family:"Font Awesome 5 Free";content:"\f0e8"}
.setting-wizard-wrap .wizard-progressbar #Budget:before{font-family:"Font Awesome 5 Free";content:"\f153"}
.setting-wizard-wrap .wizard-progressbar #Invoice:before{font-family:"Font Awesome 5 Free";content:"\f571"}
.setting-wizard-wrap .wizard-progressbar #Config:before{font-family:"Font Awesome 5 Free";content:"\f013"}
.setting-wizard-wrap .wizard-progressbar li:before{width:50px;min-width:50px;height:50px;line-height:45px;display:block;font-size:18px;color:#206f9e;-webkit-box-shadow:0 0 6px #0000008c;box-shadow:0 0 6px #0000008c;background:#f4f4f4;border-radius:50%;margin-right:10px;padding:2px}
.setting-wizard-wrap .wizard-progressbar li:after{content:'';width:2px;height:100%;background:#f4f4f4;position:absolute;left:25px;top:25px;z-index:-1}
.setting-wizard-wrap .wizard-progressbar li:last-child:after{content:none}
.setting-wizard-wrap .wizard-progressbar li.active:before,.setting-wizard-wrap .wizard-progressbar li.active:after{background:#206f9e;color:#fff}
.additional-wizard-info ul{background:#f4f4f4;width:390px;padding:20px;font-size:13px;border-radius:16px}
.additional-wizard-info ul li{display:-webkit-box;display:-ms-flexbox;display:flex}
.additional-wizard-info ul li span:first-child{display:inline-block;white-space:nowrap;border-right:1px solid #cfcfcf;padding-right:10px;min-width:140px;margin-right:10px}
.additional-wizard-info ul li span:last-child{padding-bottom:10px;font-size:12px}
.wizardSteps-wrap fieldset .form-load .form-card .row {opacity: 0.5;}
.table-card-view {padding: 1px;border-radius: 10px;overflow: hidden;-webkit-box-shadow: 0 0 0px 1px #2cc185, 2px 2px 4px 0px #00000045;box-shadow: 0 0 0px 1px #2cc185, 2px 2px 4px 0px #00000045;margin: 8px 2px !important;margin-left: 8px !important;border-top-left-radius: 0;}
.setting-wrapper .table-card-view tbody tr td{font-weight: 600 !important;}
.setting-wrapper .table-card-view tbody tr td:nth-child(odd) {color: #5c5c5c !important;}
.card-style-wrap.vessel-rate-wrap .vess-rate-lis td{font-size:13px;color:#464646;border-top:none;padding:3px 30px}
.card-style-wrap.vessel-rate-wrap .vess-rate-lis tr td:first-child{padding-left: 0;}
.card-style-wrap.vessel-rate-wrap .vess-rate-lis tr td:last-child{padding-right: 0;}
.vessel-Ration-wrap .table.theme-table-wrap thead th:first-child{min-width:200px}
.vessel-Ration-wrap .table.theme-table-wrap thead th{font-size:11px;min-width:100px;text-align:right;vertical-align:middle}
.vessel-Ration-wrap .table.theme-table-wrap tbody td{text-align:right}
.card-sty-table {border: 2px solid #dee2e6;border-radius: 10px;padding: 10px;width: auto;overflow: hidden;}
.card-sty-table table {min-width: 300px;}
.card-sty-table .card-table-title {background: #dee2e6;padding: 6px 10px;color: #414141;font-size: 15px;}
.card-sty-table .card-table-title.title-sticky {position: -webkit-sticky;position: sticky;top: 0;left: 0;}
.card-sty-table.with-title {padding: 0;padding-bottom: 10px;}
.card-style-wrap.vessel-rate-wrap .card-sty-table.with-title .vess-rate-lis tr td:first-child{padding-left: 10px;}
.card-style-wrap.vessel-rate-wrap .card-sty-table.with-title .vess-rate-lis tr td:last-child{padding-right: 10px;}
.card-style-wrap {overflow: hidden;border-radius: 10px;width: -webkit-max-content;width: -moz-max-content;width: max-content;max-width: 100%;}
.card-style-wrap .table.theme-table-wrap thead th,.card-style-wrap .table.theme-table-wrap tbody td {color: #606060;}
.card-style-wrap .table.theme-table-wrap thead th {max-width: 100px;text-align: left;vertical-align: middle;}
.card-style-wrap .table.theme-table-wrap thead th:nth-child(n+2) {text-align: center;}
.card-style-wrap .table.theme-table-wrap tbody td:nth-child(n+2) {text-align: center;}
.card-style-wrap .table tbody td input {font-size: 13px;padding: 5px 10px;height: 30px;background-color: #dee2e6;outline: none !important;-webkit-box-shadow: none !important;box-shadow: none !important;width: 80px;text-align: center;margin: 0 auto;}
.vessel-Ration-wrap.card-style-wrap .table-responsive{max-height: 400px;}
.vessel-Admin-wrap .vessel-admin-output.formBox {border-radius: 0;border: none;padding: 0;-webkit-box-shadow: none;box-shadow: none;}
.vessel-admin-output.formBox input[readonly], .vessel-admin-output.formBox input:disabled {
    background: transparent !important;
}
.confiq-output-wrap .form-title {
	font-size: 16px !important;
	color: #2CC185 !important;
}
.vessel-admin-output form {padding-left: 15px;padding-right: 15px;}
/* .wizard-fieldset-wrap .custom-select2.text-bx-wrap.input-type-select {padding: 0 !important;} */

/* Catalouge Filter Styling */
.system-cat-filter .fil-wrap-ctn .custom-select-menu.text-bx-wrap{min-width:200px}
.customer_search .custom-select-menu.text-bx-wrap:nth-of-type(3){min-width:180px!important}
.system-cat-filter .custom-select-menu.text-bx-wrap:nth-of-type(3){min-width:360px}
.system-cat-filter .card-bx-sty.table-card-wrap .btn-yellow {width: -webkit-max-content;width: -moz-max-content;width: -webkit-max-content;width: -moz-max-content;width: max-content;-webkit-box-shadow: none;box-shadow: none;padding: 5px 15px;font-weight: 500;}
.table.theme-table-wrap thead th {z-index: auto;}
.setting-wrapper.system-cat-wrap .table.theme-table-wrap thead th {z-index: 1;border-top: none;-webkit-box-shadow: 0px -1px 0 0px #dee2e6;box-shadow: 0px -1px 0 0px #dee2e6;}
.system-cat-filter .btn-yellow.bg-dark-red:hover {background-color: #fff !important;color: #C8061D}
.system-cat-filter .custom-select-menu .react-select__menu {z-index: 2;}
/* .system-cat-filter .custom-select-menu .react-select__value-container .react-select__input input[readonly], input:disabled {background: transparent !important;width: 0 !important;} */

.formBox .vessel-Admin-wrap .form-group .field-label {
    padding-right: 0;
    font-size: 16px;
    margin-bottom: 0;
}
.formBox .vessel-Admin-wrap .form-group .custom-select2.text-bx-wrap.input-type-select {
    padding: 0;
}

/* Responsive Quries */
@media (max-width: 1600px) {
    .setting-wrapper .table.theme-table-wrap thead th{font-size:11px}
    .setting-wrapper .table.theme-table-wrap tbody td{font-size:11px;color:#74777b!important}
    .invoice-info-table .form-control{height:26px;font-size:12px}
    .wizard-title{font-size:18px}
    .wizard-dis-title{font-size:13px}
    .setting-wizard-wrap .fs-title{font-size:17px}
    .setting-wizard-wrap .fs-title:before{height:1.5px;width:50px}
    .setting-wizard-wrap .fs-title:after{width:3px;height:3px;left:54px}
    .wizardSteps-wrap .action-button,.wizardSteps-wrap .action-button-previous{padding:8px 15px 7px;font-size:13px;min-width:100px}
    .wizardSteps-wrap fieldset{min-height:495px;padding-bottom:63px}
    .setting-wizard-wrap .wizard-progressbar li{font-size:12px}
    .setting-wizard-wrap .wizard-progressbar li:before{width:40px;min-width:40px;height:40px;line-height:38px;font-size:14px}
    .setting-wizard-wrap .wizard-progressbar{margin-left:-23px;width:140px}
    .setting-wizard-wrap .wizard-progressbar li:after{left:23px}
    .wizard-fieldset-wrap{width:calc(100% - 140px)}
    .wizard-inn-wrap.formBox:after{width:240px}
    .wizardSteps-wrap{margin-top:35px}
    .wizardSteps-wrap fieldset .form-card{width:calc(100% - 60px)}
    .wizardSteps-wrap .action-button:last-child{margin-right:60px}
    .wizardSteps-wrap .action-button-previous{right:170px}
    .wizardSteps-wrap .action-button,.wizardSteps-wrap .action-button-previous{bottom:30px}
    .form-thanks-you-wrap {min-height: 412px;}
    .form-thanks-you-wrap h2 {font-size: 30px;}
    .thank-you-icon-wrap h3 {margin-top: 15px;font-size: 50px;}
    .form-thanks-you-wrap p {font-size: 13px;}
    .setting-wrapper .nav-tabs .nav-link {font-size: 12px;padding: 20px 10px;}
    .settings-steps-wrap .table.theme-table-wrap thead th {font-size: 11px !important;}
    .settings-steps-wrap .table.theme-table-wrap tbody td {font-size: 11px !important;}
    .form-title {font-size: 16px !important;}
    .card-style-wrap.vessel-rate-wrap .vess-rate-lis td {font-size: 11px;}
    .card-sty-table .card-table-title {font-size: 13px;padding: 5px 10px;}
    .card-sty-table {border: 1px solid #dee2e6;}
    .card-style-wrap.vessel-rate-wrap .vess-rate-lis td {padding: 3px 25px;}
    .vessel-Ration-wrap.card-style-wrap .table.theme-table-wrap thead th {font-size: 11px;}
    .card-style-wrap .table tbody td input{font-size: 11px;padding: 3px 10px;height: 25px;width: 70px;}
    .confiq-output-wrap .form-title {font-size: 13px !important;}
    .confiq-output-wrap .vessel-rate-wrap .vess-rate-lis td {font-size: 11px !important}
    .system-cat-filter .fil-wrap-ctn .custom-select-menu.text-bx-wrap {min-width: 180px;}
    .system-cat-filter .custom-select-menu.text-bx-wrap:nth-of-type(3) {min-width: 300px;}
    .setting-wrapper.system-cat-wrap .nav-tabs .nav-link {font-size: 13px;}
    .formBox .vessel-Admin-wrap .form-group .field-label {font-size: 13px;}
}
@media (max-width: 1360px){
    .setting-wizard-wrap .wizard-progressbar {margin-left: -23px;width: 120px;}
    .wizard-fieldset-wrap {width: calc(100% - 120px);}
    .wizard-inn-wrap.formBox:after {width: 210px;}
    .setting-wrapper .nav-tabs .nav-link {font-size: 11px;padding: 15px 10px;}
    .system-cat-filter .custom-select-menu.text-bx-wrap:nth-of-type(3) {min-width: 250px;}
}
@media (max-width: 1200px) {
    .card-bx-sty.table-card-wrap{-webkit-box-orient:vertical!important;-webkit-box-direction:normal!important;-ms-flex-direction:column!important;flex-direction:column!important}
    .system-cat-filter .fil-wrap-ctn .custom-select-menu.text-bx-wrap{min-width:160px}
    .system-cat-filter .custom-select-menu.text-bx-wrap:nth-of-type(3){min-width:230px}
    .system-cat-filter .fil-wrap-ctn .custom-select-menu.text-bx-wrap{max-width:25%}
    .system-cat-filter .custom-select-menu.text-bx-wrap:nth-of-type(3){min-width:205px;max-width:48%;margin-right:0!important}
    .system-cat-filter .prov-cat-filters .fil-btn-wrap-ctn{width:100%}
    .system-cat-filter .fil-wrap-ctn{width:100%;margin-bottom:.5rem!important}
}
@media (max-width: 768px) {
    .wizard-fieldset-wrap{width:calc(100% - 100px)}
    .wizardSteps-wrap fieldset .form-card{width:calc(100% - 50px)}
    .wizardSteps-wrap .action-button:last-child{margin-right:50px}
    .wizardSteps-wrap .action-button-previous{right:160px}
    .system-cat-filter .fil-wrap-ctn {flex-direction: column !important;}
    .system-cat-filter .fil-wrap-ctn .custom-select-menu.text-bx-wrap,.system-cat-filter .custom-select-menu.text-bx-wrap:nth-of-type(3) {max-width: 100%;margin-right: 0 !important;}

}
@media (max-width: 576px) {
    .wizard-inn-wrap.formBox{padding:10px!important}
    .setting-wizard-wrap .wizard-progressbar li{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;padding-left:30px;font-size:11px}
    .setting-wizard-wrap .wizard-progressbar li:before{width:36px;min-width:36px;height:36px;line-height:32px;font-size:13px;margin-bottom:5px;margin-left:-22px}
    .setting-wizard-wrap .wizard-progressbar{width:80px}
    .wizard-fieldset-wrap{width:calc(100% - 60px)}
    .wizardSteps-wrap fieldset .form-card{width:calc(100% - 40px)}
    .wizardSteps-wrap fieldset .form-card .form-input-container label:after{padding:5px 9px;min-width:80px;height:27px;font-size:10px;line-height:16px}
    .setting-wizard-wrap .wizard-progressbar li:after{display:none!important}
    .wizard-inn-wrap.formBox:after{width:120px}
    .wizardSteps-wrap .action-button,.wizardSteps-wrap .action-button-previous{padding:6px 10px 5px;font-size:11px;min-width:60px}
    .wizardSteps-wrap .action-button:last-child{margin-right:40px}
    .wizardSteps-wrap .action-button-previous{right:105px}
    .system-cat-filter.ordCatg-table-wrap .custom-select-menu.text-bx-wrap {margin-bottom: 3px;}
}
@media (max-width: 480px) {
    .system-cat-filter .prov-cat-filters .fil-btn-wrap-ctn {flex-direction: column;}
    .system-cat-filter .prov-cat-filters .fil-btn-wrap-ctn div.d-flex.ml-1 {margin-left: 0 !important;width: 100%;max-width: 160px;}
    .system-cat-filter .card-bx-sty.table-card-wrap .btn-yellow {width: 100%;max-width: 160px;margin-left: 0 !important;margin-right: 0 !important;margin-bottom: 2px;}
}