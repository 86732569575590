#feedback-title{
	text-align:center;
	margin: 0 auto 50px auto;
	padding: 10px 5px;
  min-width: 250px;
	max-width: 400px;
	background-color: rgba(0,0,0,.03);
    border: 1px solid rgba(0,0,0,.125);
	border-radius: 5px;
	font-size: 25px;
}
#feedback-container{
	margin: auto;
	padding: 5%;
	width: 70%;
	height: auto;
	width: 500px;
	text-align: center;
	background: #FFF;
    border-bottom: 2px solid rgba(0,0,0,.125);
	border-radius: 5px;
	box-shadow: -3px 3px 20px #111111 ;
}
.feedbackLabel{
	display:inline;
	min-width: 150px;
	/* background: #b27a42; */
	margin: 0 auto;
	padding: 8px;
    border: 1px solid rgba(0,0,0,.125);
	border-radius: 5px;
	font-size: 15px;
}
.feedbackInfo{
	margin: 20px 25px;
	padding: 10px;
	width: 80%;
	/* background:#fce4c7; */
    text-align:center;
    border: 1px solid #250a72 !important;
}
.feedbackSelection{
	margin: 5%;
}
/* .btnSubmit{
	padding: 10px;
	border-top: 3px solid #f0c090;
	border-right: 3px solid #f8d2a9;
	border-left: 3px solid #C29872;
	border-bottom: 3px solid #D2A872;
	border-radius: 2px;	
	background: lightblue;
	font-size: 14px;
} */
/* .selections{
	margin:25px;
	padding:auto;
	border-top: 3px solid #f0c090;
	border-right: 3px solid #f8d2a9;
	border-left: 3px solid #C29872;
	border-bottom: 3px solid #D2A872;
} */
#comment{
	resize: none;
}
.deviceList{
	margin:auto;
}
.deviceList label{
	padding: 2px 5px 2px 5px;
	border-radius:5px;
}
.deviceList input[type=checkbox]:checked + label{
	background: rgba(225,225,75,0.9);
}
